import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../../../components/button";
import Text from "../../../../components/text";
import Flex from "../../../../components/flex";
import Icon from "../../../../components/icon";
import ArrowRightIcon from "../../../../components/svg/arrowRightIcon";
import Input from "../../../../components/input";
import Image from "../../../../components/image";
import ViewBreakupCard from "../../../../components/viewBreakupCard";
import SelectStyle from "../../../../components/input/selectStyle";
import OptionSelectItem from "../../../../components/optionSelectItem";
import INRIcon from "../../../../assets/img/icon/inr-icon.svg";
import USDTIcon from "../../../../assets/img/icon/usdt-icon.png";
import BTCIcon from "../../../../assets/img/icon/btc-icon.png";
import ETCIcon from "../../../../assets/img/icon/etc-icon.png";
import USDCIcon from "../../../../assets/img/icon/usdc-icon.png";
import useHome from "../../hooks/home.hook";
import { useDispatch, useSelector } from "react-redux";
import {
  getExchangeRatesFetch,
  getPlatformFeeFetch,
} from "../../../../redux/user/reducers";
import {
  saveSellData,
  saveSellNetworkFee,
} from "../../../../redux/withdrawal/persistReducer";
import { saveCurrency } from "../../../../redux/user/persistReducer";
import { saveSellReceiveData } from "../../../../redux/withdrawal/persistReducer";
import { getTds, truncate } from '../../../../utils/util';
import useDebounce from "../../../../hooks/useDebounce";
import InfoIcon from "../../../../components/svg/infoIcon";
import Tooltip from "../../../../components/tooltip";
import { APPLICATION_ID } from "../../../../utils/constants";

const currencyOption = [
  {
    value: "INR",
    label: (
      <OptionSelectItem src={INRIcon} text="INR" subText="Tether" min="100" />
    ),
  },
  {
    value: "USDT",
    label: (
      <OptionSelectItem src={USDTIcon} text="USDT" subText="Tether" min="100" />
    ),
  },
  {
    value: "BTC",
    label: (
      <OptionSelectItem src={BTCIcon} text="BTC" subText="Bitcoin" min="1" />
    ),
  },
  {
    value: "ETC",
    label: (
      <OptionSelectItem
        src={ETCIcon}
        text="ETC"
        subText="Ethereum Classic"
        min="20"
      />
    ),
  },
  {
    value: "USDC",
    label: (
      <OptionSelectItem
        src={USDCIcon}
        text="USDC"
        subText="USD Coin"
        min="100"
      />
    ),
  },
];

const SellForm = (props) => {
  const dispatch = useDispatch();

  const { exchangeRateSell, platformFee, sellMarkup, platformCurrencies, profileDetails, isLoading, applicationId } = useSelector(
    (state) => state?.userReducer
  );
  const { convertionCurrent, isLoggedIn, isPanAadharLinked } = useSelector(
    (state) => state?.userPersistReducer
  );
  const { sellReceiveData } = useSelector(
    (state) => state?.withdrawalPersistReducer
  );

  const {
    isViewBreakupToggle,
    onViewBreakupToggle,
    selectedBuyCurrency,
    handleBuyCurrencyChange,
  } = useHome();

  const [sellPrice, SetSellPrice] = useState('');
  const [originalQuantity, setOriginalQuantity] = useState("")
  const [convertedAmount, setConvertedAmount] = useState("");
  const [active, setActive] = useState(false)

  useEffect(() => {
    dispatch(getPlatformFeeFetch());
  }, []);

  useEffect(() => {
    let percentValue = exchangeRateSell * parseFloat(sellMarkup);
    SetSellPrice(exchangeRateSell - percentValue);
  }, [exchangeRateSell])

  const formik = useFormik({
    initialValues: {
      buyCurrencySelect: "INR",
      receiveCurrencyAmount: "",
    },
    validationSchema: Yup.object({
      buyCurrencySelect: Yup.string().required("Please enter a select"),
      receiveCurrencyAmount: Yup.number()
        .required("Please enter a receive amount"),
    }),
    onSubmit: async (values) => {
      await dispatch(saveSellData(values));
      await submitFunction(values);
    },
  });

  const debouncedHandleChange = useDebounce((value) => {
    dispatch(
      getExchangeRatesFetch({
        values: {
          currency: props.selectedCurrency,
          source: props.source,
          blockchain: props.blockchain,
          quantity: value || "1",
          decimals: props.decimals,
          applicationId : applicationId || APPLICATION_ID
        },
      })
    );
    setActive(false);
  }, 700); // 700ms debounce delay

  const handleInputChange = (event) => {
    setActive(true)
    const value = event.target.value;
    setOriginalQuantity(value);
    formik.handleChange("receiveCurrencyAmount")(event);
    getConvertedAmount(value);
    debouncedHandleChange(value);
  };

  useEffect(() => {
    if (props.selectedCurrency) {
      debouncedHandleChange(originalQuantity || "1");
    }
  }, [props.selectedCurrency, props.blockchain]);

  const feeWavier = profileDetails?.userInfo?.feeWaiver
  const { tdsPercent, tdsMessage } = getTds(isLoggedIn, isPanAadharLinked);

  let allowedCurrenciesOptions = [];

  platformCurrencies?.sellCurrecies?.map((item, index) => {
    allowedCurrenciesOptions.push({
      label: item,
      value: item,
      key: index,
    });
  });

  const submitFunction = async (values) => {
    if (
      truncate(convertedAmount - payonrampFeeValue - tdsPercentValue) < 100
      || truncate(convertedAmount - payonrampFeeValue - tdsPercentValue) > 10000
    )
      return;
    dispatch(
      saveSellNetworkFee({
        network: tdsPercent,
        payonrampFee: !feeWavier ? convertionCurrent * platformFee?.sellFeePercent * 0.01 : 0,
        total:
          convertionCurrent -
          convertionCurrent * platformFee?.sellFeePercent * 0.01 -
          tdsPercent,
      })
    );
    props.handleSubmit(values);
    setConvertedAmount("0");
    formik.resetForm();
  };

  const getConvertedAmount = (receiveAmount) => {
    setConvertedAmount(truncate(parseFloat((receiveAmount * (exchangeRateSell - (exchangeRateSell * parseFloat(sellMarkup)))))));
    dispatch(
      saveCurrency(truncate(parseFloat((receiveAmount * (exchangeRateSell - (exchangeRateSell * parseFloat(sellMarkup)))))))
    );
  };

  const reqCurrencyObj = platformCurrencies?.sellCurrecies[0]
  const propsCode = props.code ? props.code : reqCurrencyObj?.tokenType

  const clearFormFields = () => {
    formik.setFieldValue("receiveCurrencyAmount", ""); // Clear the receiveCurrencyAmount field
    document.getElementsByName("buyCurrencyAmount")[0].value = "";
    getConvertedAmount(0); // Clear the buyCurrencyAmount field
  };

  useEffect(() => {
    if (
      !sellReceiveData?.token ||
      sellReceiveData?.token !== props.selectedCurrency
    ) {
      const selectedReceiveCurrency = {
        label: (
          <OptionSelectItem
            text={reqCurrencyObj?.currency}
            subText={reqCurrencyObj?.tokenName}
            code={reqCurrencyObj?.tokenType}
          />
        ),
        value: reqCurrencyObj?.blockchain,
        token: reqCurrencyObj?.currency,
        fee: reqCurrencyObj?.minimumBuyLimit + reqCurrencyObj?.networkFee,
        networkFee: reqCurrencyObj?.networkFee,
        code: reqCurrencyObj?.tokenType,
      };
      dispatch(saveSellReceiveData(selectedReceiveCurrency));
    }
  }, []);

  let payonrampFeeValue = !feeWavier ? truncate(
    convertedAmount *
    platformFee?.sellFeePercent *
    0.01
  ) : 0;

  let tdsPercentValue = truncate(
    (convertedAmount - payonrampFeeValue) *
    (tdsPercent / 100)
  );

  return (
    <Flex flexColumn justifyContent="center">
      <form onSubmit={formik.handleSubmit}>
        <div className="card-field bg-light-green">
          <div className="row d-flex align-items-center">
            <div className="col-5 col-md-5">
              <div className="form-box">
                <Button
                  mb0
                  secondary
                  isSearchable={true}
                  text={
                    <>
                      <span className="singleLine">
                        {props.selectedCurrency}
                        {props.code !== null && <br />}
                        {props.code !== null && `on ${propsCode}`}{" "}
                      </span>
                      <span className="arrow-icon">
                        <svg
                          height="20"
                          width="20"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                          focusable="false"
                          className="css-tj5bde-Svg"
                        >
                          <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                        </svg>
                      </span>
                    </>
                  }
                  type="button"
                  className="buy-btn"
                  onClick={() => {
                    props.onClick();
                    clearFormFields();
                  }}
                />
              </div>
            </div>
            <div className="col-7 col-md-7">
              <div className="card-field-form-box">
                <label className="card-field-label">
                  {props.selectedCurrency}
                </label>
                <Input
                  secondary
                  mb0
                  noLabel
                  type="number"
                  name="receiveCurrencyAmount"
                  placeholder="Enter amount"
                  value={formik.values.receiveCurrencyAmount}
                  onChange={handleInputChange}
                  onBlur={formik.handleBlur}
                  mandatory
                  errormessage={
                    formik.touched.receiveCurrencyAmount &&
                      formik.errors.receiveCurrencyAmount
                      ? formik.errors.receiveCurrencyAmount
                      : null
                  }
                />
              </div>
              <Flex flexColumn className="mt-12">
                <Text
                  type="PARAGRAPH"
                  className="font-size-12 font-weight-500 mb-0"
                  text={`1 ${props.selectedCurrency} = ${isLoading ? "Calculating..." : `₹ ${sellPrice}`}`}
                />
              </Flex>
            </div>
          </div>
        </div>
        <Flex justifyContent="center" className="mb-4">
          <Icon gray icon={<ArrowRightIcon down />} />
        </Flex>
        <div className="card-field">
          <div className="row d-flex align-items-center">
            <div className="col-5 col-md-5">
              <div className="form-box">
                <SelectStyle
                  mb0
                  secondary
                  isSearchable={true}
                  name="buyCurrencySelect"
                  value={selectedBuyCurrency[0]}
                  defaultValue={currencyOption[0]}
                  onChange={(selectedOption) => {
                    handleBuyCurrencyChange(selectedOption);
                    formik.handleChange("buyCurrencySelect")(
                      selectedOption.value
                    );
                  }}
                  options={currencyOption}
                  errormessage={
                    formik.touched.buyCurrencySelect &&
                      formik.errors.buyCurrencySelect
                      ? formik.errors.buyCurrencySelect
                      : null
                  }
                  isDisabled
                />
              </div>
            </div>
            <div className="col-7 col-md-7">
              <div className="card-field-form-box">
                <label className="card-field-label">
                  {formik.values.buyCurrencySelect === "INR" && (
                    <Image src={INRIcon} alt="INR" />
                  )}
                  {formik.values.buyCurrencySelect === "USDT" && (
                    <Image src={USDTIcon} alt="USDT" />
                  )}
                  {formik.values.buyCurrencySelect === "BTC" && (
                    <Image src={BTCIcon} alt="BTC" />
                  )}
                  {formik.values.buyCurrencySelect === "ETC" && (
                    <Image src={ETCIcon} alt="ETC" />
                  )}
                  {formik.values.buyCurrencySelect === "USDC" && (
                    <Image src={USDCIcon} alt="USDC" />
                  )}
                  <Text
                    type="SPAN"
                    className="ml-8"
                    text={formik.values.buyCurrencySelect}
                  />
                </label>
                <Input
                  secondary
                  mb0
                  noLabel
                  type="text"
                  name="buyCurrencyAmount"
                  placeholder="You will recieve"
                  value={
                    active || isLoading ? "Calculating..." :
                    formik.values.receiveCurrencyAmount
                      ? truncate(
                        convertedAmount -
                        payonrampFeeValue -
                        tdsPercentValue
                      )
                      : ""
                  }
                  errormessage={
                    truncate(
                      convertedAmount -
                      payonrampFeeValue -
                      tdsPercentValue
                    ) < 100 && formik.values.receiveCurrencyAmount
                      ? "min Amount is 100"
                      : truncate(
                        convertedAmount -
                        payonrampFeeValue -
                        tdsPercentValue
                      ) > 10000 && formik.values.receiveCurrencyAmount
                        ? "Max Amount is 10000"
                        : ""
                  }
                  readonly={true}
                />
              </div>
            </div>
          </div>
        </div>

        <Flex flexColumn>
          <Button
            type="submit"
            text={isLoading ? "Calculating..." : "Proceed"}
            className="mb-3"
            disabled={(isLoggedIn && profileDetails?.kycInfo?.kycStatus === "BASIC_KYC_PENDING") || isLoading || active}
          />
          <Button
            type="button"
            linkDark
            text={isViewBreakupToggle ? "Hide Breakup" : "View Breakup"}
            onClick={onViewBreakupToggle}
          />
          {isViewBreakupToggle && (
            <ViewBreakupCard
              active={isViewBreakupToggle}
              lists={[
                {
                  text: `${formik.values.buyCurrencySelect + " Net Amount"}`,
                  number: isLoading ? "Calculating..." : `₹${truncate(convertedAmount || 0)}`,
                },
                {
                  text: "PayOnRamp Fee (incl. GST)",
                  number: `₹${payonrampFeeValue}`,
                },
                {
                  text: <Flex alignItems="center" justifyContent="center">TDS ({tdsPercent}%)&nbsp;
                    {!isPanAadharLinked && <Tooltip text={tdsMessage}><InfoIcon width="15" height="15" /></Tooltip>}</Flex>,
                  number: isLoading ? "Calculating..." : `₹${tdsPercentValue}`,
                },
                {
                  text: "Receive Total",
                  number: isLoading ? "Calculating..." : `₹${truncate(
                    convertedAmount -
                    payonrampFeeValue -
                    tdsPercentValue
                  )}`,
                },
              ]}
            />
          )}
        </Flex>
      </form>
    </Flex>
  );
};

export default SellForm;